/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import useToggleState from '@components/selectors/toggle-index-tracker'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { DataResponse, RisksFilters } from '@interfaces/analytics-risk'
import CohortService from '@services/api-analytics/risk-cohort'

import DataTable from './table'

const RETURNED_DATE_FORMAT = 'MMM-YY'
const RollRates = ({ exportable }: { exportable: boolean }) => {
  const { currTabs, mapToggleChange } = useToggleState([1, 0])
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    categoryTypes,
    currency = 'USD',
    activeType,
  } = appliedFilters
  const { rates = {}, display_rates = [] } = optionFilters
  const isAggregate = activeType === 'All'

  const [isVolume, setIsVolume] = useState<boolean>(true)
  const [cohort, setCohort] = useState<string>('All')

  const filters: RisksFilters = {
    date_from: moment(dateStart).format('YYYY-MM-DD'),
    date_to: moment(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    is_volume: isVolume,
    currency,
  }

  const { error, data, isFetching } = useQuery(
    ['riskCohortRollRates', filters],
    () => CohortService.getRollRates(filters),
    getStaleMins()
  )

  const cohorts = ((data?.data as unknown as any[]) ?? []).reduce(
    (prev: { cohort: string | number }[], cur: { cohort: string }) => {
      return prev.findIndex(
        (x: { cohort: string | number }) => x.cohort === cur.cohort.toString()
      ) < 0
        ? cur.cohort.toLowerCase() === 'all'
          ? [{ cohort: cur.cohort }, ...prev]
          : [...prev, { cohort: cur.cohort }]
        : prev
    },
    []
  )

  cohorts.sort((a: moment.MomentInput, b: moment.MomentInput) => {
    return moment(a, 'MMM-YY').diff(moment(b, 'MMM-YY'))
  })

  const dpds = (data?.labels as unknown as any) ?? []

  const currentData = ((data?.data as unknown as any) ?? []).filter(
    (d: { cohort: string }) => d.cohort === cohort
  )

  const chartData = dpds.map((x: DataResponse) => {
    const currentDPD: any = (currentData ?? []).find(
      (_x: { dpd_bucket: number }) => _x.dpd_bucket === x.id
    )
    const res: { x: string; [key: string]: number | string } = {
      x: x.val.toString(),
    }
    if (currentDPD) {
      Object.keys(currentDPD).forEach(c => {
        res[c] = currentDPD[c] ?? 0
      })
    }

    return res
  })
  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [cohort, data])
  /** end */

  const maxCohort = moment.max(
    cohorts
      .filter((c: { cohort: moment.MomentInput }) =>
        moment.utc(c.cohort, RETURNED_DATE_FORMAT).isValid()
      )
      .map((c: { cohort: moment.MomentInput }) =>
        moment.utc(c.cohort, RETURNED_DATE_FORMAT)
      )
  )
  const conversionCohort = moment.utc(cohort, RETURNED_DATE_FORMAT).isValid()
    ? moment.utc(cohort, RETURNED_DATE_FORMAT)
    : maxCohort

  const measurementToggle: ToggleProps = {
    toggleTitle: 'Measurement',
    toggleSelections: [
      {
        label: '# of Loans',
        action: () => {
          setIsVolume(false)
        },
      },
      {
        label: 'Volume',
        action: () => {
          setIsVolume(true)
        },
      },
    ],
  }

  const cohortToggle: ToggleProps = {
    toggleTitle: 'Cohort',
    toggleSelections: cohorts.map((c: { cohort: moment.MomentInput }) => ({
      label: c.cohort?.toString().toLowerCase() === 'all' ? 'All' : c.cohort,
      action: () => {
        const selection =
          c.cohort?.toString().toLowerCase() === 'all'
            ? 'All'
            : moment(c.cohort).format('MMM-DD')
        setCohort(selection)
      },
    })),
  }

  const toggles: ToggleProps[] = data
    ? mapToggleChange([measurementToggle, cohortToggle])
    : mapToggleChange([measurementToggle])

  return (
    <MultiToggleLayout
      toggles={toggles}
      toggleSelection={currTabs}
      exchangeRateIndicator={showHistoricalRateIndicator}
      staticTipInfo={
        <div>
          How loans roll from one delinquency bucket to another after a set
          period of time, using MOB for time snapshots and for the aggregated
          portfolio.
        </div>
      }
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`riskCohortRollRates_by_${activeType}`}
          yLabel={!isVolume ? 'Number of Loans' : 'Volume'}
          yFormat="#.00a%"
          xLabel="DPD Bucket"
          xAxisType="CategoryAxis"
          data={chartData}
          series={dpds.map((d: DataResponse) => {
            return {
              label: d.val,
              tooltipValueFormat: '#.00a%',
              type: 'ColumnSeries',
              field: `bucket_${d.id}`,
              isStack: true,
            }
          })}
          exportable={exportable}
          error={error as { message: string }}
        />
      }
      toggleData={
        <DataTable
          isVolume={isVolume}
          dpds={dpds}
          currentData={currentData}
          currency={currency}
        />
      }
    />
  )
}

export default RollRates
