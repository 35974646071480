import React from 'react'

// import moment from 'moment'
// import { useQuery } from 'react-query'
import PieChart from '@components/chart/pie'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
// import StatisticL5 from '@components/layouts/l5-stat'
// import AuthContext from '@contexts/auth'
// import { getStaleMins } from '@helpers/stale-timer'
// import { FinancialsFilters } from '@interfaces/financial'
// import { FinancialKeyIndicatorService } from '@services/api-analytics/financials-key-indicators'

const BankDistributionL5 = () => {
  /** remove blur below and update API calls when available */

  return (
    <L5ChartstatLayout
      chart={
        <div className="text-center">
          <span className="z-20 text-md absolute top-40 left-52 drop-shadow-md bg-white border rounded-lg p-5">
            Requires bank integration - Please contact admin@cascaddebt.com to
            begin
          </span>
          <div className="blur-md bg-white/30 w-full text-center">
            <PieChart
              id={`bankDistribution`}
              data={[
                {
                  account: 'Bank Account 1',
                  balance: 100000,
                },
                {
                  account: 'Bank Account 2',
                  balance: 160000,
                },
                {
                  account: 'Bank Account 3',
                  balance: 80400,
                },
                {
                  account: 'Bank Account 4',
                  balance: 80000,
                },
                {
                  account: 'Bank Account 5',
                  balance: 40000,
                },
                {
                  account: 'Bank Account 6',
                  balance: 40000,
                },
              ]}
              label1Text={'Total Balance'}
              label2Text={'xxx xxx.xx'}
              label3Text={'185 Transactions'}
              categoryField={'account'}
              valueField={'balance'}
            />
          </div>
        </div>
      }
      //stat={<StatisticL5 {...growthRatios} />}
    />
  )
}

export default BankDistributionL5
