import React, { useContext, useEffect, useState } from 'react'

import FilterCurrency from '@components/filters/filter-dates-currency'
import BaseLayout from '@components/layouts/base-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'

import OverviewL4 from './overview'

const RiskOverviewL3 = () => {
  const { activeFilters, company, appliedFilters, setAppliedFilters } =
    useContext(AuthContext)

  const { activeType, range, currency } = activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && !!currency) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    range === 0 &&
    activeType === 'All' &&
    currency === company?.currencies_available?.[0]?.to_currency

  return (
    <BaseLayout
      title="Risk Overview"
      filters={{
        left: <FilterCurrency dateAsOfType={filterDateAsOfType.loanTape} />,
      }}
      disableReset={disableReset}
    >
      <OverviewL4 />
    </BaseLayout>
  )
}
export default RiskOverviewL3
