import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { AvailableFeatures } from '@components/app-routes/routes'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import {
  ArrowDownIcon,
  ArrowPathIcon,
  ArrowUpIcon,
} from '@heroicons/react/24/outline'
import { OverviewDataResponse, RisksFilters } from '@interfaces/analytics-risk'
import { Alert, Card, CardBody } from '@material-tailwind/react'
import OverviewService from '@services/api-analytics/risk-overview'

const OverviewCard = ({ metric, val, delta, ratio }: OverviewDataResponse) => {
  const { userMetadata, appliedFilters, company } = useContext(AuthContext)
  const userCompanyAccess = userMetadata?.companies?.[company?.slug_name ?? '']
  const activeAccess = Object.keys(userCompanyAccess?.access ?? {})
  const features = AvailableFeatures(activeAccess)

  const { currency = 'USD' } = appliedFilters
  const card_link = () => {
    if (['collection'].some(x => metric.toLowerCase().includes(x))) {
      return '/analytics/risk/collection?tab=collection-rate'
    }
    if (['apr'].some(x => metric.toLowerCase().includes(x))) {
      return '/analytics/risk/characteristics?tab=interest-rate'
    }
    if (['rdr', 'par'].some(x => metric.toLowerCase().includes(x))) {
      return '/analytics/risk/delinquency'
    }
    if (['volume', 'clients'].some(x => metric.toLowerCase().includes(x))) {
      return '/analytics/risk/traction'
    }
    return ``
  }

  const card_title = () => {
    if (['collection'].some(x => metric.toLowerCase().includes(x))) {
      return 'Collection'
    }
    if (['apr'].some(x => metric.toLowerCase().includes(x))) {
      return 'Characteristics'
    }
    if (['rdr', 'par'].some(x => metric.toLowerCase().includes(x))) {
      return 'Delinquency'
    }
    if (['volume', 'clients'].some(x => metric.toLowerCase().includes(x))) {
      return 'Traction'
    }
    return ``
  }

  const unit = () => {
    if (['client'].some(x => metric.toLowerCase().includes(x))) {
      return ``
    }
    if (['volume'].some(x => metric.toLowerCase().includes(x))) {
      return currency
    }
    return `%`
  }

  const card_value = (value: number) => {
    return `${Intl.NumberFormat(undefined, {
      style: 'decimal',
      notation: 'compact',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)} ${unit()}`
  }

  const up_class = () => {
    if (['par', 'rdr'].some(x => metric.toLowerCase().includes(x))) {
      return `bg-danger-main`
    }
    return `bg-success-main`
  }
  const down_class = () => {
    if (['par', 'rdr'].some(x => metric.toLowerCase().includes(x))) {
      return `bg-success-main`
    }
    return `bg-danger-main`
  }

  const card_badge_class = () => {
    if (ratio > 0) {
      return up_class()
    }
    if (ratio < 0) {
      return down_class()
    }
    return `bg-cc-primary-gray-light`
  }

  const card_badge_icon = () => {
    if (ratio > 0) {
      return <ArrowUpIcon className="w-3 h-3 mr-1" />
    }
    if (ratio < 0) {
      return <ArrowDownIcon className="w-3 h-3 mr-1" />
    }
    return <></>
  }

  return features.find(f =>
    f.startsWith(`analytics_risk_${card_title().toLowerCase()}`)
  ) ? (
    <Link to={card_link()} id="card">
      <Card className="hover:bg-gradient-to-r hover:from-cc-primary-gray-medium/100 hover:to-cc-primary-gray-medium/50 group rounded-md">
        <CardBody className="flex flex-col justify-between h-[300px]">
          <Typography
            className="text-cc-primary-gray-dark mb-4 font-semibold group-hover:text-neutral-white"
            id="card-title"
          >
            {card_title()}
          </Typography>

          <div className="flex flex-col gap-1">
            <Typography
              className="text-xs text-neutral-body-2 group-hover:text-neutral-white"
              id="metric-name"
            >
              {metric}
            </Typography>
            <Typography
              variant="h4"
              className="font-semibold text-cc-text-primary group-hover:text-neutral-white"
              id="card-value"
            >
              {card_value(val)}
            </Typography>
          </div>

          <div className="flex flex-col gap-1.5">
            <Typography className="text-xs text-neutral-body-2 group-hover:text-neutral-white">
              Current Month Change
            </Typography>
            <Typography
              className="text-xl font-semibold text-cc-text-primary group-hover:text-neutral-white"
              id="month-change"
            >
              {card_value(delta)}
            </Typography>
            <div className="flex">
              <Typography
                className={`inline-flex py-1 px-2.5 items-center text-xs font-medium rounded-xl text-neutral-black group-hover:text-neutral-body-2 group-hover:bg-neutral-white ${card_badge_class()}`}
                id="percent"
              >
                {card_badge_icon()}
                {`${Intl.NumberFormat(undefined, {
                  style: 'decimal',
                  notation: 'compact',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(ratio)}%`}
              </Typography>
            </div>
          </div>
        </CardBody>
      </Card>
    </Link>
  ) : null
}

const OverviewL4 = () => {
  const { company, appliedFilters } = useContext(AuthContext)

  const { currency = 'USD' } = appliedFilters
  const filters: RisksFilters = {
    slug_name: company?.slug_name ?? '',
    currency,
  }

  const { data, isFetching, error } = useQuery(
    ['overview', filters],
    () => OverviewService.getMetric(filters),
    {
      ...getStaleMins(),
      enabled: !!currency && !!company?.slug_name,
    }
  )

  const overview_data: any[] = data?.data ?? []

  return isFetching ? (
    <div className="w-full flex justify-center bg-neutral-white rounded-lg p-8 ">
      <ArrowPathIcon className="animate-spin text-primary-main w-8" />
    </div>
  ) : error ? (
    <div className="w-full flex justify-center bg-neutral-white rounded-lg p-8 ">
      <Alert className="w-1/2 text-danger-main border border-danger-main text-center">
        Unexpected Error has occured.
      </Alert>
    </div>
  ) : (
    <div className="grid gird-cols-1 sm:grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 2xl:grid-cols-3">
      {overview_data.map((d, i) => (
        <OverviewCard key={i} {...d} />
      ))}
    </div>
  )
}
export default OverviewL4
