import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { CollectionService } from '@services/api-analytics/risk-collection'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'

const CollectionByStatus = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)

  const {
    dateStartCohort,
    dateEndCohort,
    activeType,
    categoryTypes = [],
    currency = 'USD',
  } = appliedFilters
  const isAggregate = activeType === 'All'
  const { rates = {}, display_rates = [] } = optionFilters

  const filters: RisksFilters = {
    date_from: moment(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    is_aggregate: isAggregate,
    currency,
  }

  const { error, data, isFetching } = useQuery(
    ['collectionByStatus', filters],
    () => CollectionService.getCollectionByStatus(filters),
    getStaleMins()
  )

  const chartData = (data?.data || []).map(x => {
    const typecasetedX = x as unknown as { [key: string]: number | string }
    const result = historicalExchgList(rates, x, typecasetedX.cohort)
    Object.keys(x).forEach((key: string) => {
      result[key] = parseFloat((typecasetedX[key] ?? 0).toString())
    })

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  const series = [
    {
      label: 'Prepaid',
      field: 'cbs_prepaid',
    },
    {
      label: 'On Time',
      field: 'cbs_on_time',
    },
    {
      label: 'Late',
      field: 'cbs_late',
    },
  ]

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px]',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...series.map((s: any) => {
      return {
        className: 'min-w-[150px]',
        align: 'right',
        field: `${s.field}_numer`,
        title: s.label,
        head: { align: 'center' },
        render: (r: any) => numberFormatter(r[`${s.field}_numer`]),
      } as any
    }),
    {
      title: 'Total',
      field: 'denom',
      align: 'right',
      className: 'min-w-[150px]',
      head: { align: 'center' },
      render: (r: any) => numberFormatter(r['denom']),
    },
  ]

  return (
    <MultiToggleLayout
      toggles={false}
      staticTipInfo={
        <div>
          Collection by Status.
          <br />
          <br />
          Prepaid = Prepayment. <br />
          On-time = 0 DPD when a payment is made. <br /> Late &ge; 0 DPD when a
          payment is made.
        </div>
      }
      exchangeRateIndicator={showHistoricalRateIndicator}
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`riskCollectionByStatus_by_${activeType}`}
          yLabel="Percentage"
          yFormat="#.00a%"
          data={chartData}
          series={series.map(x => ({
            ...x,
            tooltipValueFormat: '#.00a%',
            type: 'ColumnSeries',
            isStack: true,
          }))}
          tooltipSubtitle={
            showHistoricalRateIndicator
              ? displayRates(display_rates)
              : undefined
          }
          exportable={exportable}
          exportableColumn={table_columns}
          error={error as { message: string }}
        />
      }
      toggleData={
        <Table
          containerClass="[&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
          loading={isFetching || isProcessing}
          data={chartData}
          columns={table_columns}
        />
      }
    />
  )
}

export default CollectionByStatus
