import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import useToggleState from '@components/selectors/toggle-index-tracker'
import Table from '@components/table'
import { AVG_MONTH_THRESHOLD_DAYS } from '@constants/app'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { DelinquencyService } from '@services/api-analytics/risk-delinquency'
import FilterService from '@services/api-analytics/risk-filter'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'

const OutstandingDelinq = ({ exportable }: { exportable: boolean }) => {
  const { currTabs, mapToggleChange } = useToggleState([0])
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)

  const [isAdjusted, setIsAdjusted] = useState<boolean>(false)

  const outstandingToggle: ToggleProps = {
    toggleTitle: 'Balance Type',
    toggleSelections: [
      {
        label: 'Outstanding Balance',
        textInfo: {
          primary:
            'Outstanding Balance: all unpaid principal as of a specific date.',
        },
        action: () => {
          setIsAdjusted(false)
        },
      },
      {
        label: 'Adjusted Outstanding Balance',
        textInfo: {
          primary:
            'Adjusted Outstanding Balance: all unpaid principal as of specific date + paid principal for loans disbursed and paid in same month.',
        },
        action: () => {
          setIsAdjusted(true)
        },
      },
    ],
  }

  const toggles: ToggleProps[] = mapToggleChange([outstandingToggle])

  const {
    dateStart,
    dateEnd,
    categoryTypes = [],
    currency = 'USD',
    activeType,
  } = appliedFilters
  const isAggregate = activeType === 'All'

  const avgFilters = {
    date_from: moment(dateStart).format('YYYY-MM-DD'),
    date_to: moment(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    currency,
  }
  const { rates = {}, display_rates = [] } = optionFilters
  const {
    data: avgTerm,
    isFetching: avgTermIsFetching,
    isFetched: avgTermIsFetched,
  } = useQuery(
    ['avgTerm', avgFilters],
    () => FilterService.getAvgTerm(avgFilters),
    { ...getStaleMins(), enabled: categoryTypes?.length > 0 }
  )

  const filters = {
    ...avgFilters,
    is_adjusted: isAdjusted,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['outstandingDelinq', filters],
    () => DelinquencyService.getOutstandingDelinq(filters),
    {
      ...getStaleMins(),
      enabled: avgTermIsFetched,
    }
  )

  const { data } = fetchedData ?? {}

  const chartData = (data || []).map(x => {
    const typecastX = x as unknown as { [key: string]: number | string }
    const result = historicalExchgList(rates, x, typecastX.cohort)
    Object.keys(x).forEach((key: any) => {
      result[key] = parseFloat((typecastX[key] ?? 0).toString())
    })
    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  const showAdjustedToggle = avgTermIsFetched
    ? Number(avgTerm ?? 0) <= AVG_MONTH_THRESHOLD_DAYS
    : false

  const series = [
    { label: '0 DPD', field: 'obd0' },
    { label: '1-7 DPD', field: 'obd1' },
    { label: '8-14 DPD', field: 'obd8' },
    { label: '15-29 DPD', field: 'obd15' },
    { label: '30-45 DPD', field: 'obd30' },
    { label: '46-59 DPD', field: 'obd46' },
    { label: '60-89 DPD', field: 'obd60' },
    { label: '90-119 DPD', field: 'obd90' },
    { label: '120+ DPD', field: 'obd120' },
  ].map(x => ({
    ...x,
    tooltipValueFormat: '#.00a%',
    type: 'ColumnSeries',
    isStack: true,
    field: `${isAdjusted ? 'adjusted_' : ''}${x.field}`,
  })) as any

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px]',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...series.map((s: any) => {
      return {
        className: 'min-w-[150px]',
        align: 'right',
        field: `${s.field}_outstanding`,
        title: s.label,
        head: { align: 'center' },
        render: (r: any) => numberFormatter(r[`${s.field}_outstanding`]),
      }
    }),
    {
      title: 'Total Outstanding Balance',
      field: 'total_outstanding_balance',
      className: 'min-w-[150px]',
      align: 'right',
      head: { align: 'center' },
      render: (r: any) => numberFormatter(r['total_outstanding_balance']),
    },
  ]

  return (
    <MultiToggleLayout
      toggles={showAdjustedToggle && toggles}
      toggleSelection={currTabs}
      staticTipInfo={
        <div>
          The proportion of the outstanding balance by value in each delinquency
          bucket, for the whole loan portfolio for a given date.
          <br />
          <br />
          Formula:
          <br />
          For each delinquency bucket: outstanding balance in that bucket /
          total outstanding balance
          <br />
          <br />
          Write Offs = {company?.writeoff_days} days
        </div>
      }
      chart={
        <Chart
          loading={isFetching || isProcessing || avgTermIsFetching}
          id="outstandingDelinq"
          yLabel="Percentage"
          yFormat="#.00a%"
          data={chartData}
          series={series}
          tooltipSubtitle={
            showHistoricalRateIndicator
              ? displayRates(display_rates)
              : undefined
          }
          exportable={exportable}
          exportableColumn={table_columns}
          error={error as { message: string }}
        />
      }
      toggleData={
        <Table
          containerClass="[&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
          loading={isFetching || isProcessing || avgTermIsFetching}
          data={chartData}
          columns={table_columns}
        />
      }
    />
  )
}

export default OutstandingDelinq
