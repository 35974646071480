import React, { useContext, useEffect, useState } from 'react'

import FilterDateRange from '@components/filters/filter-date-range'
import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterTypeKey from '@components/filters/filter-type-key'
import BaseLayout from '@components/layouts/base-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { Card, CardBody } from '@material-tailwind/react'

import IndicatorsL4 from './indicator'

const IndicatorsL3 = () => {
  const { activeFilters, company, appliedFilters, setAppliedFilters } =
    useContext(AuthContext)

  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    activeType,
    range,
    currency,
  } = activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      dateStartCohort &&
      dateEndCohort &&
      !!categoryTypes &&
      categoryTypes.length > 0
    ) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    range === 0 &&
    activeType === 'All' &&
    currency === company?.currencies_available?.[0]?.to_currency

  return (
    <BaseLayout
      title="Key Indicators"
      filters={{
        left: <FilterCurrency dateAsOfType={filterDateAsOfType.loanTape} />,
        middle: <FilterDateRange isCohort={true} />,
        right: <FilterTypeKey />,
      }}
      disableReset={disableReset}
    >
      <Card className="rounded-md py-3 px-3.5">
        <CardBody className="p-0 ">
          <IndicatorsL4 />
        </CardBody>
      </Card>
    </BaseLayout>
  )
}
export default IndicatorsL3
