import React, { useContext, useEffect, useState } from 'react'
import { format } from 'date-fns'

import { AvailableFeatures } from '@components/app-routes/routes'
import BasicDialog from '@components/basic-dialog/basic-dialog'
import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterFinancialDisplay from '@components/filters/filter-financial-display'
import OverviewL2 from '@components/layouts/l2-overview-layout'
import { SUPPORT_EMAIL } from '@constants/config'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { FinancialKeyIndicatorService } from '@services/api-analytics/financials-key-indicators'
import { StatementService } from '@services/api-analytics/financials-statement'

const FinancialOverviewL3 = () => {
  const {
    userMetadata,
    company,
    activeFilters,
    appliedFilters,
    optionFilters,
    setAppliedFilters,
  } = useContext(AuthContext)

  const active_company = userMetadata?.companies?.[company?.slug_name ?? '']
  const active_access = Object.keys(active_company?.access ?? {})
  const features = AvailableFeatures(active_access)

  const { currency, financialEntityID: appliedFinancialEntityID } =
    appliedFilters
  const { financialEntities } = optionFilters
  const { entityStartDate, entityEndDate, range, financialEntityID } =
    activeFilters

  const [featureUnavailDialogOpen, setFeatureUnavailDialogOpen] =
    useState<boolean>(false)
  const [fetcherReady, setFetcherReady] = useState<boolean>(false)

  const appliedEntity = financialEntities?.filter(
    (entity: any) => entity.entity_id == appliedFinancialEntityID
  )[0]

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)

  useEffect(() => {
    if (
      !filterReady &&
      entityStartDate &&
      entityEndDate &&
      financialEntityID &&
      range !== undefined
    ) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name, financialEntities])

  useEffect(() => {
    optionFilters.financialEntities?.length == 0 &&
      setFeatureUnavailDialogOpen(true)
  }, [optionFilters])

  useEffect(() => {
    if (
      optionFilters.financialEntities ||
      (!!financialEntityID &&
        appliedEntity?.end_date &&
        appliedEntity?.start_date &&
        currency &&
        company?.slug_name)
    ) {
      setFetcherReady(true)
    }
  }, [optionFilters])

  const handleDialogOpen = () => {
    setFeatureUnavailDialogOpen(false)
  }

  const dateEnd = appliedEntity?.end_date
    ? format(new Date(appliedEntity.end_date), 'yyyy-MM-dd')
    : null

  const dateStart = appliedEntity?.start_date
    ? format(new Date(appliedEntity.start_date), 'yyyy-MM-dd')
    : null

  const disableReset =
    financialEntities?.[0]?.entity_id === financialEntityID &&
    financialEntities?.[0]?.currency === currency

  const data = [
    //total assets
    {
      feature: 'analytics_financials_statements',
      cardTitle: 'Balance Sheet',
      cardLink: '/analytics/financials/statements?tab=balance-sheet',
      fetcher: StatementService.getOverview,
      fetchOption: {
        slugName: company?.slug_name,
        entityId: appliedFinancialEntityID,
        dateTo: dateEnd,
        dateFrom: dateStart,
        currency,
      },
      icon: 'bar',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format(val ?? 0)} ${currency ?? ''}`,
    },
    //cash balance
    {
      feature: 'analytics_financials_key-indicators',
      cardTitle: 'KPI',
      cardLink: '/analytics/financials/key-indicators?tab=cash',
      fetcher: FinancialKeyIndicatorService.getOverview,
      icon: 'bar',
      fetchOption: {
        slugName: company?.slug_name,
        dateTo: dateEnd,
        dateFrom: dateStart,
        entityId: appliedFinancialEntityID,
        key: 'cash',
        currency,
      },
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format(val ?? 0)} ${currency ?? ''}`,
    },
    //cash runway
    {
      feature: 'analytics_financials_key-indicators',
      cardTitle: 'KPI',
      cardLink: '/analytics/financials/key-indicators?tab=runway',
      fetcher: FinancialKeyIndicatorService.getOverview,
      icon: 'line',
      fetchOption: {
        slugName: company?.slug_name,
        dateTo: dateEnd,
        dateFrom: dateStart,
        entityId: appliedFinancialEntityID,
        key: 'runway',
        metric: 'previous',
        currency,
      },
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          maximumFractionDigits: 1,
          style: 'decimal',
          notation: 'compact',
        }).format(val ?? 0)}`,
      indicatorClass: {
        up: 'bg-green',
        down: 'bg-red',
      },
    },
    //pNL - net income
    {
      feature: 'analytics_financials_statements',
      cardTitle: 'Profit & Loss',
      cardLink: `/analytics/financials/statements?tab=${encodeURIComponent(
        'profit-and-loss'
      )}`,
      fetcher: FinancialKeyIndicatorService.getOverview,
      fetchOption: {
        slugName: company?.slug_name,
        dateTo: dateEnd,
        dateFrom: dateStart,
        entityId: appliedFinancialEntityID,
        key: 'net_income',
        currency,
      },
      icon: 'line',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format(val ?? 0)} ${currency ?? ''}`,

      indicatorClass: {
        up: 'bg-green',
        down: 'bg-red',
      },
    },
    //debt:equity
    {
      feature: 'analytics_financials_key-indicators',
      cardTitle: 'KPI',
      cardLink: '/analytics/financials/key-indicators?tab=debt:equity',
      fetcher: FinancialKeyIndicatorService.getOverview,
      fetchOption: {
        slugName: company?.slug_name,
        dateTo: dateEnd,
        dateFrom: dateStart,
        entityId: appliedFinancialEntityID,
        key: 'debt_to_equity',
        currency,
      },
      icon: 'line',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
          maximumFractionDigits: 2,
        }).format(val ?? 0)}`,
      indicatorClass: {
        up: 'bg-red',
        down: 'bg-green',
      },
    },
    //tangible net worth
    {
      feature: 'analytics_financials_key-indicators',
      cardTitle: 'KPI',
      cardLink: '/analytics/financials/key-indicators?tab=net-worth',
      fetcher: FinancialKeyIndicatorService.getOverview,
      fetchOption: {
        slugName: company?.slug_name,
        dateTo: dateEnd,
        dateFrom: dateStart,
        entityId: appliedFinancialEntityID,
        key: 'net_worth',
        currency,
      },
      icon: 'line',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format(val ?? 0)} ${currency ?? ''}`,
    },
  ].filter(d => features.includes(d.feature))

  return (
    <>
      <OverviewL2
        title="Financials Overview"
        filters={{
          left: <FilterCurrency dateAsOfType={filterDateAsOfType.financials} />,
          middle: <FilterFinancialDisplay />,
        }}
        data={data}
        disableReset={disableReset}
        fetchReady={fetcherReady}
      />
      <BasicDialog
        open={featureUnavailDialogOpen}
        handleOpen={handleDialogOpen}
        headerText={'No Financial Information Uploaded'}
        description={
          <div>
            Financial dashboards require further accounting integration or
            statement uploads. Please contact us at{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`}> {SUPPORT_EMAIL}</a> for further
            information
          </div>
        }
      />
    </>
  )
}
export default FinancialOverviewL3
