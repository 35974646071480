import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'

import ProfitAndLossL5 from './profit-and-loss'

const ProfitAndLossL4 = () => {
  const tabs = [
    {
      label: 'P&L',
      component: <ProfitAndLossL5 />,
    },
  ]

  return <L4TabsLayout tabs={tabs} />
}

export default ProfitAndLossL4
