import moment from 'moment'
export const historicalExchgList = (
  exchangeRatesList: any,
  x: any,
  cohort: any
) => {
  const ratesExchange = exchangeRatesList[cohort] ?? {}

  const result: { x: number; [key: string]: number } = {
    x: moment(cohort).valueOf(),
    ...Object.keys(ratesExchange).reduce((p = {}, r: string) => {
      return {
        ...p,
        [`fx_${r}`]: Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(ratesExchange[r]),
      }
    }, {}),
  }

  return result
}

export const displayRates = (display_rates: string[] = []) => {
  return display_rates.map((r: string) => {
    return {
      field: `fx_${r}`,
      title: `USD:${r}`,
    }
  })
}
