import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import StatisticL5 from '@components/layouts/l5-stat'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import useToggleState from '@components/selectors/toggle-index-tracker'
import Table from '@components/table'
import { ColumnProps } from '@components/table/type'
import { AVG_MONTH_THRESHOLD_DAYS } from '@constants/app'
import { REMOUNT_MS } from '@constants/config'
import { Role } from '@constants/role'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { createSeries } from '@helpers/risk-charts-data-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Filter } from '@interfaces/analytics-risk-filter-type-key'
import FilterService from '@services/api-analytics/risk-filter'
import { TractionService } from '@services/api-analytics/risk-traction'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'

const Balance = () => {
  const { currTabs, mapToggleChange } = useToggleState([0, 0])
  const { userCompanyAccess, role, filterTogglesByAccess } =
    useUserAccessFeature()

  const activeAccess = userCompanyAccess?.access ?? {}
  const FEATURE = `analytics_risk_traction_balance`

  const [isTotal, setIsTotal] = useState<boolean>(false)
  const [isAdjusted, setIsAdjusted] = useState<boolean>(false)

  const outstandingToggle: ToggleProps = {
    toggleTitle: 'Outstanding Loan Metrics',
    toggleSelections: filterTogglesByAccess(FEATURE, [
      {
        label: 'Balance Volume',
        textInfo: {
          primary:
            'All unpaid principal as of specific date + paid principal for loans disbursed and paid in same month.',
          secondary:
            'Formula: Sum of to_principal (schedules) - to_principal (payments) for all non-written off loans at a given date.',
        },
        action: () => {
          setIsTotal(false)
        },
      },
      {
        label: 'Number of Loans',
        textInfo: {
          primary:
            'The number of loans with an outstanding balance > 0 (not written-off).',
          secondary:
            'Formula: Count of loans with outstanding_balance > 0 at a given date.',
        },
        action: () => {
          setIsTotal(true)
        },
      },
    ]),
  }

  const adjustedToggle: ToggleProps = {
    toggleTitle: 'Outstanding',
    toggleSelections: [
      {
        label: 'Regular',
        textInfo: {
          primary:
            'Outstanding Balance: all unpaid principal as of a specific date.',
        },
        action: () => {
          setIsAdjusted(false)
        },
      },
      {
        label: 'Adjusted',
        textInfo: {
          primary:
            'Adjusted Outstanding Balance: all unpaid principal as of specific date + paid principal for loans disbursed and paid in same month.',
          secondary: '',
        },
        action: () => {
          setIsAdjusted(true)
        },
      },
    ],
  }

  const exportable =
    role !== Role.custom
      ? true
      : role === Role.custom &&
        activeAccess[
          `${FEATURE}_${isTotal ? 'balance-volume' : '#-of-loans'}`
        ].includes('export')

  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    currency = 'USD',
    activeType,
  } = appliedFilters
  const {
    categoryTypeOptions = [],
    rates = {},
    display_rates = [],
  } = optionFilters

  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const avgFilters = {
    slug_name: company?.slug_name ?? '',
    filters: categoryTypes,
    date_from: moment(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment(dateEndCohort).format('YYYY-MM-DD'),
  }

  const {
    data: avgTerm,
    isFetching: avgTermIsFetching,
    isFetched: avgTermIsFetched,
  } = useQuery(
    ['avgTerm', avgFilters],
    () => FilterService.getAvgTerm(avgFilters),
    { ...getStaleMins(), enabled: categoryTypes?.length > 0 }
  )

  const filters: RisksFilters = {
    ...avgFilters,
    filters: isAggregate ? [] : categoryTypes,
    is_aggregate: isAggregate,
    is_adjusted: isTotal ? false : isAdjusted,
    currency,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['balance', isTotal, filters],
    () =>
      isTotal
        ? TractionService.getTotalLoans(filters)
        : TractionService.getOutstandingBalance(filters),
    {
      ...getStaleMins(),
      enabled: avgTermIsFetched,
    }
  )

  const { data, ...growthRatios } = fetchedData ?? {}

  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  const chartData = cohort.map(c => {
    const result = historicalExchgList(rates, c, c.cohort)
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
    } else {
      categoryTypes.forEach((ct: number) => {
        const cohortCategory = cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [currency, data])

  const series = createSeries(
    isAggregate,
    categoryTypes,
    activeVal,
    currency,
    isTotal,
    'All',
    isTotal ? 'Loan Count' : `Balance (${currency})`
  )
  const table_columns = [
    {
      title: 'Month',
      field: 'x',
      align: 'center',
      width: 200,
      render: (r: any) => {
        return moment.utc(r.x).format('YYYY-MM-DD')
      },
    },
    ...series.map((s: any) => {
      return {
        width: 200,
        align: 'right',
        field: s.field,
        title: s.label,
        render: (r: any) => numberFormatter(r[s.field]),
      }
    }),
  ]

  const showAdjustedToggle = avgTermIsFetched
    ? Number(avgTerm ?? 0) <= AVG_MONTH_THRESHOLD_DAYS
    : false

  const toggles: ToggleProps[] = showAdjustedToggle
    ? mapToggleChange([outstandingToggle, adjustedToggle])
    : mapToggleChange([outstandingToggle])

  return (
    <MultiToggleLayout
      toggles={toggles}
      toggleSelection={currTabs}
      exchangeRateIndicator={showHistoricalRateIndicator && !isTotal}
      chart={
        <Chart
          loading={isFetching || isProcessing || avgTermIsFetching}
          data={chartData}
          id={`riskTractionBalance${isTotal ? '_total' : '_'}by_${activeType}`}
          yLabel={isTotal ? 'Count' : currency}
          series={series}
          tooltipSubtitle={
            showHistoricalRateIndicator && !isTotal
              ? displayRates(display_rates)
              : undefined
          }
          error={error as { message: string }}
          exportable={exportable}
          exportableColumn={table_columns}
        />
      }
      stat={<StatisticL5 {...growthRatios} />}
      toggleData={
        <Table
          containerClass=""
          loading={isFetching || isProcessing}
          columns={table_columns as ColumnProps[]}
          data={chartData}
        />
      }
    />
  )
}

export default Balance
