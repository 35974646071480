import React from 'react'
import moment from 'moment'

import { redGreenHeatmap } from '@helpers/heatmap-palettes'

interface TableProps {
  showHistoricalRateIndicator: boolean
  isVolume: boolean
  displayRates: any[]
  mob: number
  cohort: { cohort: string; color: string }[]
  data: any
  rates: Record<string, any>
  maxVal: number
  currency: string
  isQuarter: boolean
}

const DataTable: React.FC<TableProps> = ({
  showHistoricalRateIndicator,
  isVolume,
  displayRates,
  mob,
  cohort,
  data,
  rates,
  maxVal,
  currency,
  isQuarter,
}) => {
  return (
    <div
      className={`overflow-auto rounded-lg border border-neutral-border-1 max-h-[calc(100vh-100px)]`}
    >
      <table className="border-separate border-spacing-0">
        <thead className="sticky top-0 z-20">
          <tr>
            <th className="p-3 border-r border-b border-neutral-border-1 text-sm text-neutral-body-1 lg:min-w-[100px] sticky left-0 bg-neutral-white">
              Cohort
            </th>
            {showHistoricalRateIndicator &&
              isVolume &&
              displayRates.map((r: any) => (
                <th
                  key={r}
                  className="p-3 border-r border-b border-neutral-border-1 text-sm text-neutral-body-1 lg:min-w-[100px] bg-neutral-white"
                >
                  USD:{r}
                </th>
              ))}
            <th className="p-3 border-r border-b border-neutral-border-1 text-sm text-neutral-body-1 lg:min-w-[100px] sticky left-[100px] bg-neutral-white">
              {`${!isVolume ? 'Number of Loans' : 'Volume'}`}
            </th>
            {Array(mob)
              .fill('')
              .map((_, i) => (
                <th
                  key={i}
                  className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1"
                >
                  {i + 1}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {cohort.map(c => {
            const rowData = (data?.data ?? []).filter(
              (x: any) => x.cohort === c.cohort
            )

            return (
              <tr key={c.cohort}>
                <td className="p-3 border-r border-b border-neutral-border-1 sticky left-0 text-sm text-center bg-neutral-white z-10">
                  {`${isQuarter ? 'Q' : ''}${moment(c.cohort).format(
                    isQuarter ? 'Q YY' : 'MMM YY'
                  )}`}
                </td>
                {showHistoricalRateIndicator &&
                  isVolume &&
                  displayRates.map((r: any) => (
                    <td
                      key={r}
                      className="p-3 border-r border-b border-neutral-border-1 text-sm text-right bg-neutral-white z-10"
                    >
                      {Intl.NumberFormat(undefined, {
                        style: 'decimal',
                        maximumFractionDigits: 2,
                      }).format(rates[c.cohort]?.[r] ?? 1)}
                    </td>
                  ))}
                <td className="p-3 border-r border-b border-neutral-border-1 sticky left-[100px] text-sm text-right bg-neutral-white z-10">
                  {`${Intl.NumberFormat(undefined, {
                    style: 'decimal',
                    maximumFractionDigits: 1,
                    notation: 'compact',
                  }).format(
                    parseFloat((rowData?.[0]?.['new_loans'] ?? 0).toString())
                  )} ${!isVolume ? '' : currency}`}
                </td>
                {Array(mob)
                  .fill('')
                  .map((_, i) => {
                    const dt = rowData.find((x: any) => x.mob === i + 1)
                    if (i + 1 > (rowData[0]?.cutoff_age ?? 0)) {
                      return <td key={i} className="bg-neutral-border-1" />
                    }
                    const val = dt?.outstanding_loans
                      ? parseFloat(dt.outstanding_loans.toString())
                      : 0
                    const valRange =
                      maxVal > 0 ? Math.floor((val / maxVal) * 100) : 0
                    const backgroundColor =
                      redGreenHeatmap[Math.min(valRange, 100)]

                    return (
                      <td
                        key={i}
                        className={`p-3 border-r border-b border-neutral-border-1 text-sm text-right relative text-white`}
                        style={{ backgroundColor }}
                      >
                        {Intl.NumberFormat(undefined, {
                          style: 'percent',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(val / 100)}
                      </td>
                    )
                  })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default DataTable
