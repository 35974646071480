import React, { useContext } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import MultiLevelTable from '@components/multi-level-table'
import MultiOptionButtonGroup from '@components/selectors/multi-option-button'
import ApplicationCustomContext from '@contexts/application-custom-context'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { FinancialsStatementsRequest } from '@interfaces/financial'
import { Alert, Tooltip, Typography } from '@material-tailwind/react'
import { StatementService } from '@services/api-analytics/financials-statement'

const BalanceSheetL4 = () => {
  const { appliedFilters, company, optionFilters } = useContext(AuthContext)
  const {
    entityStartDate,
    entityEndDate,
    financialEntityID,
    historicalRate,
    currency,
  } = appliedFilters
  const { financialEntities } = optionFilters
  const activeFinancialEntity = financialEntities?.find(
    (fe: any) => fe.entity_id === financialEntityID
  )
  const { showRawFinancials, setShowRawFinancials } = useContext(
    ApplicationCustomContext
  )

  const filters: FinancialsStatementsRequest = {
    from_date: moment(entityStartDate).format('YYYY-MM-DD'),
    to_date: moment(entityEndDate).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    legal_entity_id: financialEntityID,
    raw: activeFinancialEntity?.is_default_template
      ? 'false'
      : `${showRawFinancials}`,
    currency: currency,
  }

  const {
    error,
    data = {},
    isFetching,
  } = useQuery(
    ['balanceSheet', filters],
    () => StatementService.getFinancialsBalanceSheet(filters),
    getStaleMins()
  )

  return (
    <div>
      {!isFetching ? (
        error ? (
          <div
            className={`flex flex-col w-full min-h-[400px] justify-center items-center`}
          >
            <Alert className="w-1/2 text-danger-main border border-danger-main text-center">
              Unexpected Error has occured.
            </Alert>
          </div>
        ) : (
          <>
            {!activeFinancialEntity?.is_default_template &&
              (data as any)?.data && (
                <MultiOptionButtonGroup
                  buttonSelections={[
                    ...[
                      {
                        options: [
                          {
                            title: 'Standardized',
                            active: !showRawFinancials,
                            action: () => setShowRawFinancials(false),
                          },
                          {
                            title: 'Original',
                            active: showRawFinancials,
                            action: () => setShowRawFinancials(true),
                          },
                        ],
                      },
                    ],
                  ]}
                />
              )}
            {!(data as any)?.data && (
              <div
                className={`flex flex-col w-full min-h-[400px] justify-center items-center`}
              >
                <Alert className="w-1/2 text-neutral-subtitle-3 border border-primary-border text-center">
                  No available data to display
                </Alert>
              </div>
            )}
            {(data as any)?.data && (
              <MultiLevelTable
                defaultCurrency={activeFinancialEntity.currency}
                dataSet={data}
                currency={currency}
                showTotals={true}
              />
            )}
            {(data as any)?.data && (
              <>
                <div className="flex justify-between mt-4">
                  <div className="flex justify-center items-center">
                    <Tooltip
                      content={<span>Balance Sheets</span>}
                      placement="right"
                    >
                      <InformationCircleIcon className="w-8 text-cc-icon-primary cursor-help hover:text-primary-hover" />
                    </Tooltip>
                    {
                      <div className="flex flex-row mx-4">
                        <Typography className="text-md font-medium text-left ">
                          Currency :
                        </Typography>
                        <Typography className="text-md font-bold text-left pl-2">
                          {currency}
                        </Typography>{' '}
                      </div>
                    }
                    {historicalRate && (
                      <ExchangeRateBadge isHistorical={!!historicalRate} />
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )
      ) : (
        <div className="w-full h-[300px] flex justify-center items-center">
          <ArrowPathIcon className="animate-spin text-primary-main w-8" />
        </div>
      )}
    </div>
  )
}

export default BalanceSheetL4
