import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import StatisticL5 from '@components/layouts/l5-stat'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { CollectionService } from '@services/api-analytics/risk-collection'

const PRRR = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    activeType,
    currency = 'USD',
  } = appliedFilters
  const { categoryTypeOptions } = optionFilters

  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const filters: RisksFilters = {
    date_from: moment(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    is_aggregate: isAggregate,
    currency,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['prepayment', filters],
    () => CollectionService.getPrepaymentRate(filters),
    getStaleMins()
  )

  const { data, ...growthRatios } = fetchedData ?? {}

  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  const chartData = cohort.map(c => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.cohort).valueOf(),
    }
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
      result.type_all_denom = parseFloat(
        (cohortData?.[0]?.denom ?? 0).toString()
      )
      result.type_all_numer = parseFloat(
        (cohortData?.[0]?.numer ?? 0).toString()
      )
    } else {
      categoryTypes.forEach((ct: number) => {
        const cohortCategory = cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
        result[`type_${ct}_denom`] = parseFloat(
          (cohortCategory?.denom ?? 0).toString()
        )
        result[`type_${ct}_numer`] = parseFloat(
          (cohortCategory?.numer ?? 0).toString()
        )
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  const series = isAggregate
    ? [
        {
          label: 'Prepayment Rate',
          tooltipValueFormat: '#.00a%',
          field: 'type_all',
          type: 'ColumnSeries',
        },
      ]
    : (categoryTypes || []).map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: typeVal?.type,
          tooltipValueFormat: '#.00a%',
          field: `type_${ct}`,
          isStack: true,
          isTotal: false,
          color,
          type: 'ColumnSeries',
        }
      })

  const header_data = [
    series.reduce((p: any, s: any) => {
      return {
        ...p,
        [`${s.field}_denom`]: 'Disbursed Principal',
        [`${s.field}_numer`]: `Total Prepayment`,
        [s.field]: 'Prepayment Rate (%)',
      }
    }, {}),
  ]

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px]',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...series.reduce((p: any[], s: any) => {
      return [
        ...p,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_numer`,
          title: header_data[0][`${s.field}_numer`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[`${s.field}_numer`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_denom`,
          title: header_data[0][`${s.field}_denom`],
          head: { colSpan: 0 },
          render: (r: any) => numberFormatter(r[`${s.field}_denom`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: s.field,
          title: `Prepayment Rate ${isAggregate ? '' : `(${s.label})`}`,
          head: { colSpan: 3, align: 'center' },
          render: (r: any) => numberFormatter(r[s.field]),
        } as any,
      ]
    }, []),
  ]

  return (
    <MultiToggleLayout
      toggles={false}
      staticTipInfo={
        <div>
          The percentage of the disbursed principal that is prepaid.
          <br />
          <br />
          Formula: At each date snapshot: Total Prepayment / Disbursed Principal
        </div>
      }
      exchangeRateIndicator={showHistoricalRateIndicator}
      isHistorical={false}
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`riskCollectionPRRPrepayment_by_${activeType}`}
          yLabel="Percentage"
          yFormat="#.00a%"
          ySetting={{ min: 0 }}
          data={chartData}
          series={series}
          exportable={exportable}
          exportableColumn={table_columns}
          error={error as { message: string }}
        />
      }
      stat={
        <StatisticL5
          classSetting={{ up: 'text-danger-hover', down: 'text-success-hover' }}
          {...growthRatios}
        />
      }
      toggleData={
        <Table
          containerClass="[&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
          loading={isFetching || isProcessing}
          data={chartData}
          headerData={header_data}
          columns={table_columns}
        />
      }
    />
  )
}

export default PRRR
