import React, { useContext } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import BaseLayout from '@components/layouts/base-layout'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { getStaleMins } from '@helpers/stale-timer'
import {
  ArrowPathIcon,
  ExclamationCircleIcon,
  FolderIcon,
} from '@heroicons/react/24/outline'
import { Card, Tooltip } from '@material-tailwind/react'
import LoantapeService from '@services/api-analytics/data-source-loantape'

const LoantapeOverviewL3 = () => {
  const { company } = useContext(AuthContext)
  const { get_access } = useUserAccessFeature()
  const feature = `analytics_data-exports_loan-tape`
  const can_export = get_access(feature, 'export')

  const { error, data, isFetching } = useQuery(
    ['loantapes', company?.slug_name],
    () => LoantapeService.getAvailableLoantapes(company?.slug_name),
    getStaleMins()
  )
  const availableDownloads = data?.data

  return (
    <BaseLayout title="Loantape">
      <div className="flex flex-col md:flex-row w-full">
        {isFetching && (
          <div className="w-full flex justify-center my-10">
            <ArrowPathIcon className="w-6 h-6 text-primary-main animate-spin m-6" />
          </div>
        )}
        {error && (
          <Card className="w-full">
            <div className="px-10 py-10 flex flex-row items-center">
              <ExclamationCircleIcon className="w-7 m-5 text-danger-main" />
              <span className="font-light max-w-1/4">An Error occured</span>
            </div>
          </Card>
        )}
        {availableDownloads && availableDownloads.length <= 0 && (
          <Card className="w-full">
            <div className="px-10 py-10 flex flex-rcol items-center">
              <ExclamationCircleIcon className="w-7 m-5" />
              <span className="font-light max-w-1/4">
                No available data to display
              </span>
            </div>
          </Card>
        )}
        {availableDownloads &&
          availableDownloads.length > 0 &&
          availableDownloads.map((download: any, x: number) => {
            const splits = download.Key.split('/')
            const fileName = splits[splits.length - 1]
            return (
              <Card
                key={x}
                className="mr-5 mt-3 static cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 sm:w-full md:w-1/2 2xl:w-1/4"
                onClick={() => {
                  if (can_export) {
                    window.open(download.DownloadUrl, '_blank')
                  }
                }}
              >
                <Tooltip
                  content={can_export ? <div>Click to download</div> : null}
                  placement="bottom"
                >
                  <div className="px-10 py-10 flex flex-col items-center">
                    <FolderIcon className="w-20" />
                    <span className="font-light max-w-1/4">{fileName}</span>
                    <span className="font-light text-xs">
                      As of {moment(download.LastModified).format('LL')}
                    </span>
                  </div>
                </Tooltip>
              </Card>
            )
          })}
      </div>
    </BaseLayout>
  )
}
export default LoantapeOverviewL3
