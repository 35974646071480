import React from 'react'

interface DataResponse {
  id: number
  val: string
}

interface TableProps {
  isVolume: boolean
  dpds: DataResponse[]
  currentData: any[]
  currency: string
}

const DataTable: React.FC<TableProps> = ({
  isVolume,
  dpds,
  currentData,
  currency,
}) => {
  return (
    <div
      className={`overflow-auto rounded-lg border border-neutral-border-1 max-h-[calc(100vh-100px)] cascade-table`}
    >
      <table className="border-separate border-spacing-0">
        <thead className="sticky top-0 z-10">
          <tr>
            <th
              className="p-3 text-sm text-neutral-body-1 lg:min-w-[120px] sticky left-0 bg-neutral-white"
              style={{ zIndex: 2 }}
            >
              <span className="absolute right-0 top-0 h-full border-r border-neutral-border-1"></span>
            </th>
            <th
              className="p-3 text-sm text-neutral-body-1 lg:min-w-[120px] sticky left-[120px] bg-neutral-white"
              style={{ zIndex: 2 }}
            >
              {`${!isVolume ? 'Number of Loans' : 'Volume'}`}
              <span className="absolute right-0 top-0 h-full border-r border-neutral-border-1"></span>
            </th>
            {dpds.map(d => (
              <th
                key={d.id}
                className="p-3 min-w-[120px] border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1"
              >
                {d.val}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dpds.map(d => {
            const currentCohort = currentData.find(x => x.dpd_bucket === d.id)

            return (
              <tr key={d.id}>
                {/* First column */}
                <td
                  className="p-3 sticky left-0 bg-neutral-white text-sm text-center border-r-0"
                  style={{ zIndex: 1 }}
                >
                  {d.val}
                  <span className="absolute right-0 top-0 h-full border-r border-neutral-border-1"></span>
                </td>
                {/* Second column */}
                <td
                  className="p-3 sticky left-[120px] bg-neutral-white text-sm text-right border-r-0"
                  style={{ zIndex: 1 }}
                >
                  {`${Intl.NumberFormat(undefined, {
                    style: 'decimal',
                    maximumFractionDigits: 1,
                    currency,
                    notation: 'compact',
                  }).format(
                    parseFloat((currentCohort?.['loans_total'] ?? 0).toString())
                  )} ${!isVolume ? '' : currency}`}
                  <span className="absolute right-0 top-0 h-full border-r border-neutral-border-1"></span>
                </td>
                {/* Remaining columns */}
                {dpds.map(_d => {
                  const current = currentCohort?.[`bucket_${_d.id}`]
                  return (
                    <td
                      key={_d.id}
                      className="p-3 border-r border-b border-neutral-border-1 text-sm text-right"
                    >
                      {Intl.NumberFormat(undefined, {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      }).format(parseFloat((current ?? 0).toString()) / 100)}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default DataTable
