/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import useToggleState from '@components/selectors/toggle-index-tracker'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import CohortService from '@services/api-analytics/risk-cohort'
import FilterService from '@services/api-analytics/risk-filter'

import DataTable from './table'

const RETURNED_DATE_FORMAT = 'MMM-YY'
const CohortRollRates = ({ exportable }: { exportable: boolean }) => {
  const { currTabs, mapToggleChange } = useToggleState([1, 0])

  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    categoryTypes,
    currency = 'USD',
    activeType,
  } = appliedFilters
  const { display_rates = [], rates = {} } = optionFilters
  const isAggregate = activeType === 'All'
  const [isVolume, setIsVolume] = useState<boolean>(true)
  const [cohort, setCohort] = useState<string>('All')

  const avgFilters = {
    date_from: moment(dateStart).format('YYYY-MM-DD'),
    date_to: moment(dateEnd).format('YYYY-MM-DD'),
    filters: isAggregate ? [] : categoryTypes,
    slug_name: company?.slug_name ?? '',
  }

  const {
    data: avgTerm,
    isFetching: avgTermIsFetching,
    isFetched: avgTermIsFetched,
  } = useQuery(
    ['avgTerm', avgFilters],
    () => FilterService.getAvgTerm(avgFilters),
    { ...getStaleMins(), enabled: categoryTypes?.length > 0 }
  )

  const filters: RisksFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: categoryTypes,
    is_volume: isVolume,
    currency,
  }

  const { error, data, isFetching } = useQuery(
    ['riskCohortRollRatesCohort', filters],
    () => CohortService.getCohortRollRates(filters),
    { ...getStaleMins(), enabled: avgTermIsFetched }
  )

  const cohorts = (data?.data ?? []).reduce(
    (prev: string[], cur) =>
      prev.includes(cur.cohort)
        ? prev
        : cur.cohort.toLowerCase().includes('all')
        ? [cur.cohort, ...prev]
        : [...prev, cur.cohort],
    []
  )

  const dpds = (data?.data ?? [])
    .reduce(
      (prev: string[], cur) =>
        prev.includes(cur.bucket_label) ? prev : [...prev, cur.bucket_label],
      []
    )
    .sort((a, b) =>
      a.toLowerCase() === 'paid off'
        ? -1
        : Number(a.split(/(?:[-+\s]+)/)[0]) - Number(b.split(/(?:[-+\s]+)/)[0])
    )

  const currentData = (data?.data ?? []).filter(d => d.cohort === cohort)
  const currentMoBs = currentData?.reduce(
    (prev: number[], cur) =>
      prev.includes(cur.mob) ? prev : [...prev, cur.mob],
    []
  )

  const chartData = currentMoBs.map(m => {
    const res: { x: number; [key: string]: number | string } = { x: m }
    dpds.forEach(d => {
      const current = currentData.find(x => x.bucket_label === d && x.mob === m)
      res[d] = !current ? 0 : current.val
    })
    return res
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [cohort, data, isVolume])
  /** end */

  /**
   * zoom
   */

  const xStart = 0
  const xSpan = Math.ceil(Number(avgTerm ?? 0) / 30) + 5
  const xEnd =
    chartData?.length === 0
      ? 0
      : xSpan >= chartData?.length
      ? chartData?.[chartData?.length - 1]?.x
      : chartData?.[xSpan - (xSpan > 0 ? 1 : 0)].x /
        chartData?.[chartData?.length - 1]?.x

  const maxCohort = moment.max(
    cohorts
      .filter(c => moment.utc(c, RETURNED_DATE_FORMAT).isValid())
      .map(c => moment.utc(c, RETURNED_DATE_FORMAT))
  )
  const conversionCohort = moment.utc(cohort, RETURNED_DATE_FORMAT).isValid()
    ? moment.utc(cohort, RETURNED_DATE_FORMAT)
    : maxCohort

  const measurementToggle: ToggleProps = {
    toggleTitle: 'Measurement',
    toggleSelections: [
      {
        label: '# of Loans',
        action: () => {
          setIsVolume(false)
        },
      },
      {
        label: 'Volume',
        action: () => {
          setIsVolume(true)
        },
      },
    ],
  }

  const cohortToggle: ToggleProps = {
    toggleTitle: 'Cohort',
    toggleSelections: cohorts.map(c => ({
      label: c.toLowerCase().includes('all')
        ? c.split('_').join(' ')
        : moment(c, 'MMM-YY').format('MMM-YY'),
      action: () => {
        const selection = c.toLowerCase().includes('all')
          ? c.split('_').join(' ')
          : moment(c, 'MMM-YY').format('MMM-YY')
        setCohort(selection)
      },
    })),
  }

  const toggles: ToggleProps[] = data
    ? mapToggleChange([measurementToggle, cohortToggle])
    : mapToggleChange([measurementToggle])

  return (
    <MultiToggleLayout
      toggles={toggles}
      toggleSelection={currTabs}
      exchangeRateIndicator={showHistoricalRateIndicator}
      staticTipInfo={
        <div>
          <div className="flex flex-col gap-2">
            <p>
              The proportion of outstanding balance or number of loans in each
              delinquency bucket, for a monthly cohort, tracked based on months
              on book. Provides a view of how each cohort behaved for every
              month it was on book.
            </p>

            <p>
              Same as Delinquency by Cohort but captured for each month on book.
            </p>
            <p>All: combines all loans disbursed to date in one cohort.</p>
            <p>
              All MoB 6: combines all loans disbursed to date with at least 6
              months on book into one cohort.
            </p>
            <p>
              All MoB 12: combines all loans disbursed to date with at least 12
              months on book into one cohort.
            </p>
          </div>
        </div>
      }
      chart={
        <Chart
          loading={isFetching || isProcessing || avgTermIsFetching}
          id={`riskCohortRollRatesCohort_by_${activeType}`}
          yLabel={!isVolume ? 'Number of Loans' : 'Volume'}
          yFormat="#.00a%"
          xLabel="Months on Books"
          xAxisType="CategoryAxis"
          data={chartData}
          series={[...dpds].map(d => {
            return {
              label: d,
              tooltipValueFormat: '#.00a%',
              type: 'ColumnSeries',
              field: d,
              isStack: true,
            }
          })}
          exportable={exportable}
          error={error as { message: string }}
          scroll={{ y: true, x: true, xStart, xEnd }}
        />
      }
      toggleData={
        <DataTable
          isVolume={isVolume}
          dpds={dpds}
          currentMoBs={currentMoBs}
          currentData={currentData}
          currency={currency}
        />
      }
    />
  )
}

export default CohortRollRates
