import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'

import CustomSheetL5 from './custom-sheet'

const CustomSheetL4 = () => {
  const tabs = [
    {
      label: 'Custom Sheet',
      component: <CustomSheetL5 />,
    },
  ]

  return <L4TabsLayout tabs={tabs} />
}

export default CustomSheetL4
