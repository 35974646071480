import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import FilterDateRange from '@components/filters/filter-date-range'
import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterFinancialDisplay from '@components/filters/filter-financial-display'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { EntityInfo } from '@interfaces/financial'

import CashL4 from './cash'
import DebtEquityL4 from './debt-equity'
import NetIncomeL4 from './net-income'
import NetWorthL4 from './net-worth'
import RunwayL4 from './runway'

const KeyIndicatorsL3 = () => {
  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `analytics_financials_key-indicators`
  const {
    activeFilters,
    company,
    appliedFilters,
    optionFilters,
    setAppliedFilters,
  } = useContext(AuthContext)

  const { range, entityStartDate, entityEndDate, financialEntityID } =
    activeFilters

  const { financialEntities } = optionFilters

  const activeEntity = financialEntities?.find(
    (entity: EntityInfo) => entity.entity_id == financialEntityID
  )

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      entityStartDate &&
      entityEndDate &&
      financialEntityID &&
      range !== undefined
    ) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name, financialEntities])

  const start = appliedFilters?.entityStartDate
    ? moment.utc(appliedFilters?.entityStartDate).format('yyyy-MM-DD')
    : undefined
  const end = appliedFilters?.entityEndDate
    ? moment.utc(appliedFilters?.entityEndDate).format('yyyy-MM-DD')
    : undefined
  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    financialEntities?.[0]?.entity_id === financialEntityID &&
    appliedFilters.currency ===
      company?.currencies_available?.[0]?.to_currency &&
    activeEntity?.start_date === start &&
    activeEntity?.end_date === end

  const tabs = [
    { label: 'Net Income', component: NetIncomeL4 },
    { label: 'Cash', component: CashL4 },
    { label: 'Net Worth', component: NetWorthL4 },
    { label: 'Runway', component: RunwayL4 },
    { label: 'Debt:Equity', component: DebtEquityL4 },
  ]
    .filter(t =>
      filter_access(`${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`)
    )
    .map(t => {
      const exportable = get_access(
        `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`,
        'export'
      )
      return { ...t, component: <t.component exportable={exportable} /> }
    })

  return (
    <L3TabsLayout
      title="Key Indicators"
      filters={{
        left: <FilterCurrency dateAsOfType={filterDateAsOfType.financials} />,
        middle: <FilterDateRange type={'financials'} />,
        right: <FilterFinancialDisplay />,
      }}
      disableReset={disableReset}
      tabs={tabs}
    />
  )
}
export default KeyIndicatorsL3
