import React, { useContext } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import MultiLevelTable from '@components/multi-level-table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { FinancialsStatementsRequest } from '@interfaces/financial'
import { Alert, Typography } from '@material-tailwind/react'
import { StatementService } from '@services/api-analytics/financials-statement'

const CustomSheetL5 = () => {
  const { appliedFilters, company, optionFilters } = useContext(AuthContext)
  const {
    entityStartDate,
    entityEndDate,
    financialEntityID,
    historicalRate,
    currency,
  } = appliedFilters
  const { financialEntities } = optionFilters

  const activeFinancialEntity = financialEntities?.find(
    (fe: any) => fe.entity_id === financialEntityID
  )

  const filters: FinancialsStatementsRequest = {
    from_date: moment(entityStartDate).format('YYYY-MM-DD'),
    to_date: moment(entityEndDate).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    legal_entity_id: financialEntityID,
    raw: 'false',
    currency: currency,
  }

  const {
    error,
    data = {},
    isFetching,
  } = useQuery(
    ['customSheet', filters],
    () => StatementService.getFinancialsCustomSheet(filters),
    getStaleMins()
  )

  return (
    <div>
      {!isFetching ? (
        error ? (
          <div
            className={`flex flex-col w-full min-h-[400px] justify-center items-center`}
          >
            <Alert className="w-1/2 text-danger-main border border-danger-main text-center">
              Unexpected Error has occured.
            </Alert>
          </div>
        ) : (
          <>
            {!(data as any)?.data && (
              <div
                className={`flex flex-col w-full min-h-[400px] justify-center items-center`}
              >
                <Alert className="w-1/2 text-neutral-subtitle-3 border border-primary-border text-center">
                  No available data to display
                </Alert>
              </div>
            )}
            {(data as any)?.data && (
              <MultiLevelTable
                defaultCurrency={activeFinancialEntity.currency}
                dataSet={data}
                currency={currency}
                showTotals={true}
              />
            )}
            {(data as any)?.data && (
              <>
                <div className="flex justify-between mt-4">
                  <div className="flex justify-center items-center">
                    {
                      <div className="flex flex-row mx-4">
                        <Typography className="text-md font-medium text-left ">
                          Currency :
                        </Typography>
                        <Typography className="text-md font-bold text-left pl-2">
                          {currency}
                        </Typography>{' '}
                      </div>
                    }
                    {historicalRate && (
                      <ExchangeRateBadge isHistorical={!!historicalRate} />
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )
      ) : (
        <div className="w-full h-[300px] flex justify-center items-center">
          <ArrowPathIcon className="animate-spin text-primary-main w-8" />
        </div>
      )}
    </div>
  )
}

export default CustomSheetL5
