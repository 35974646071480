import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { CollectionService } from '@services/api-analytics/risk-collection'

const AggregatedRollRatesL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()

  const { company, appliedFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    activeType,
    categoryTypes = [],
    currency = 'USD',
  } = appliedFilters

  const filters: RisksFilters = {
    date_from: moment(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: categoryTypes,
    is_aggregate: true,
    currency,
  }

  const { error, data, isFetching } = useQuery(
    ['aggregatedRollRates', filters],
    () => CollectionService.getAggregatedRollRates(filters),
    getStaleMins()
  )

  const dt = (data?.data || [])?.[0] ?? {}
  const chartData = [
    '0 DPD',
    '1-14 DPD',
    '15-29 DPD',
    '30-59 DPD',
    '60-89 DPD',
    '90-119 DPD',
    '120+ DPD',
  ].map((x, i) => ({
    x,
    improve: parseFloat((dt[`improve_${i + 1}`] ?? 0).toString()),
    improve_numer: parseFloat((dt[`numer_improve_${i + 1}`] ?? 0).toString()),
    nochange: parseFloat((dt[`nochange_${i + 1}`] ?? 0).toString()),
    nochange_numer: parseFloat((dt[`numer_nochange_${i + 1}`] ?? 0).toString()),
    worsen: parseFloat((dt[`worsen_${i + 1}`] ?? 0).toString()),
    worsen_numer: parseFloat((dt[`numer_worsen_${i + 1}`] ?? 0).toString()),
    paidoff: parseFloat((dt[`paidoff_${i + 1}`] ?? 0).toString()),
    paidoff_numer: parseFloat((dt[`numer_paidoff_${i + 1}`] ?? 0).toString()),
  }))

  const series = [
    { field: 'paidoff', label: 'Paid Off' },
    { field: 'improve', label: 'Improve' },
    { field: 'nochange', label: 'No Change' },
    { field: 'worsen', label: 'Worsen' },
  ]

  const table_columns = [
    {
      title: 'DPD Bucket',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px]',
    },
    ...series.map((s: any) => {
      return {
        className: 'min-w-[150px]',
        align: 'right',
        field: `${s.field}_numer`,
        title: s.label,
        head: { align: 'center' },
        render: (r: any) => numberFormatter(r[`${s.field}_numer`]),
      } as any
    }),
  ]

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  return (
    <MultiToggleLayout
      toggles={false}
      staticTipInfo={
        <div>
          The behaviour of loans that are in one delinquency bucket as they
          “roll” into other delinquency buckets 30 days later.
          <br />
          <br />
          Formula: For each month-end, track which delinquency bucket the
          outstanding balance ended up in 30 days later.
        </div>
      }
      exchangeRateIndicator={showHistoricalRateIndicator}
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`riskCollectionARR_by_${activeType}`}
          yLabel="Percentage"
          yFormat="#.00a%"
          xLabel="Starting DPD Bucket"
          xAxisType="CategoryAxis"
          data={chartData}
          series={series.map(s => ({
            ...s,
            tooltipValueFormat: '#.00a%',
            type: 'ColumnSeries',
            isStack: true,
          }))}
          exportable={exportable}
          exportableColumn={table_columns}
          error={error as { message: string }}
        />
      }
      toggleData={
        <Table
          containerClass="[&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
          loading={isFetching || isProcessing}
          data={chartData}
          columns={table_columns}
        />
      }
    />
  )
}

export default AggregatedRollRatesL4
