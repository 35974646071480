import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import StatisticL5 from '@components/layouts/l5-stat'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import useToggleState from '@components/selectors/toggle-index-tracker'
import Table from '@components/table'
import { ColumnProps } from '@components/table/type'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { numberFormatter } from '@helpers/number-formatter'
import { createSeries } from '@helpers/risk-charts-data-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Filter } from '@interfaces/analytics-risk-filter-type-key'
import { TractionService } from '@services/api-analytics/risk-traction'

const ClientL4 = () => {
  const { currTabs, mapToggleChange } = useToggleState([0])
  const { get_access, filterTogglesByAccess } = useUserAccessFeature()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const [isTotal, setIsTotal] = useState<boolean>(false)

  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    activeType,
  } = appliedFilters
  const { categoryTypeOptions } = optionFilters

  const FEATURE = `analytics_risk_traction_clients`

  const principalToggle: ToggleProps = {
    toggleTitle: 'Sample Range',
    toggleSelections: filterTogglesByAccess(FEATURE, [
      {
        label: 'Monthly Active Clients',
        textInfo: {
          primary: 'The number of clients active in each calendar month.',
          secondary:
            'Formula: Count of Active Clients at the end of each month (active at some point in the same month).',
        },
        action: () => {
          setIsTotal(false)
        },
      },
      {
        label: 'Total Unique Clients',
        textInfo: {
          primary:
            'The number of unique clients the company has had since the beginning of operations.',
          secondary:
            'Formula: Total count of unique customer_id from beginning to a given date.',
        },
        action: () => {
          setIsTotal(true)
        },
      },
    ]),
  }

  const toggles: ToggleProps[] = mapToggleChange([principalToggle])

  const exportable = get_access(
    `${FEATURE}_${principalToggle.toggleSelections[
      currTabs[0]
    ].label.toLowerCase()}`,
    'export'
  )

  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const filters: RisksFilters = {
    date_from: moment(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    is_aggregate: isAggregate,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['clients', isTotal, filters],
    () =>
      isTotal
        ? TractionService.getTotalUniqueClients(filters)
        : TractionService.getMonthlyActiveClients(filters),
    getStaleMins()
  )
  const { data, ...growthRatios } = fetchedData ?? {}
  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  const chartData = cohort.map(c => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.cohort).valueOf(),
    }
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
    } else {
      categoryTypes.forEach((ct: number) => {
        const cohortCategory = cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  const series = createSeries(
    isAggregate,
    categoryTypes,
    activeVal,
    '', // No currency needed
    false,
    'Clients',
    'Clients'
  )

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      width: 200,
      render: (r: any) => {
        return moment.utc(r.x).format('YYYY-MM-DD')
      },
    },
    ...series.map((s: any) => {
      return {
        width: 200,
        align: 'right',
        field: s.field,
        title: s.label,
        render: (r: any) => numberFormatter(r[s.field]),
      }
    }),
  ]
  return (
    <MultiToggleLayout
      toggles={toggles}
      toggleSelection={currTabs}
      chart={
        <Chart
          loading={isFetching || isProcessing}
          data={chartData}
          id={`riskTractionClient${isTotal ? '_total' : '_'}by_${activeType}`}
          yLabel="Count"
          series={series}
          error={error as { message: string }}
          exportable={exportable}
          exportableColumn={table_columns}
        />
      }
      stat={<StatisticL5 {...growthRatios} />}
      toggleData={
        <Table
          loading={isFetching || isProcessing}
          columns={table_columns as ColumnProps[]}
          data={chartData}
        />
      }
    />
  )
}

export default ClientL4
