import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import useToggleState from '@components/selectors/toggle-index-tracker'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import CohortService from '@services/api-analytics/risk-cohort'
import FilterService from '@services/api-analytics/risk-filter'

import DataTable from './table'

const VintageAnalysisL4 = ({ exportable }: { exportable: boolean }) => {
  const { currTabs, mapToggleChange } = useToggleState([0, 0])
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const showHistoricalRateIndicator = useDisbursementCurrencies()

  const dpdToggle: ToggleProps = {
    toggleTitle: 'DPD Frequency',
    toggleSelections: Array(3)
      .fill('')
      .map((_, i) => {
        const cdpd = (30 * (i + 1)).toString()
        return {
          label: `${cdpd} DPD`,
          textInfo: {
            primary:
              'Measurement can be Volume (Outstanding Balance) or Number of Loans.',
            secondary:
              'Formula: Measurement in DPD divided by Total Measurement, by cohort, by month-on-books.',
          },
          action: () => {
            setDPD(cdpd)
          },
        }
      }),
  }

  const measurementToggle: ToggleProps = {
    toggleTitle: 'Measurement',
    toggleSelections: [
      {
        label: 'Volume',
        action: () => {
          setIsVolume(true)
        },
      },
      {
        label: 'Number of Loans',
        action: () => {
          setIsVolume(false)
        },
      },
    ],
  }
  const toggles: ToggleProps[] = mapToggleChange([dpdToggle, measurementToggle])

  const {
    dateStart,
    dateEnd,
    categoryTypes,
    currency = 'USD',
    activeType,
  } = appliedFilters
  const { rates = {}, display_rates = [] } = optionFilters
  const isAggregate = activeType === 'All'

  const [isQuarter] = useState<boolean>(false)
  const [dpd, setDPD] = useState<string>('30')
  const [isVolume, setIsVolume] = useState<boolean>(true)

  const avgFilters = {
    date_from: moment(dateStart).format('YYYY-MM-DD'),
    date_to: moment(dateEnd).format('YYYY-MM-DD'),
    filters: isAggregate ? [] : categoryTypes,
    slug_name: company?.slug_name ?? '',
  }

  const {
    data: avgTerm,
    isFetching: avgTermIsFetching,
    isFetched: avgTermIsFetched,
  } = useQuery(
    ['avgTerm', avgFilters],
    () => FilterService.getAvgTerm(avgFilters),
    { ...getStaleMins(), enabled: categoryTypes?.length > 0 }
  )

  const filters: RisksFilters = {
    slug_name: company?.slug_name ?? '',
    val_dpd: dpd,
    currency: currency,
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    filters: categoryTypes,
    is_percentage: true,
    is_quarter: isQuarter,
    is_volume: isVolume,
  }

  const { error, data, isFetching } = useQuery(
    ['riskCohortVintage', filters],
    () => CohortService.getVintageAnalysis(filters),
    { ...getStaleMins(), enabled: avgTermIsFetched }
  )

  const cohort = (data?.data ?? []).reduce(
    (prev: { cohort: string; color: string; cutoff_age: number }[], cur) => {
      const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
      return prev.findIndex(
        (x: { cohort: string }) => x.cohort === cur.cohort
      ) < 0
        ? [...prev, { cohort: cur.cohort, color, cutoff_age: cur.cutoff_age }]
        : prev
    },
    []
  )

  const mob =
    (data?.data ?? []).length > 0
      ? Math.max(...(data?.data ?? []).map(x => x.cutoff_age))
      : 0

  let maxVal = 0
  const suppData: any = { x: isVolume ? `Volume(${currency})` : 'No. of loans' }

  data?.data.forEach(dataItem => {
    cohort.forEach((c: { cohort: string; cutoff_age: number }) => {
      if (dataItem.cohort === c.cohort) {
        suppData[moment(c.cohort).format('MMM-YY')] = dataItem.new_loans
      }
    })
  })

  const chartData = [
    ...Array(mob)
      .fill('')
      .map((x, i) => {
        const currentMob = (data?.data ?? []).filter(x => x.mob === i + 1)
        const result: { x: number; [key: string]: number | undefined } = {
          x: i + 1,
        }
        cohort.forEach((y: { cohort: string; cutoff_age: number }) => {
          const currentVal = currentMob.find(x => x.cohort === y.cohort)
          const val = currentVal?.outstanding_loans
            ? parseFloat(currentVal?.outstanding_loans.toString())
            : 0

          if (val > maxVal) {
            maxVal = val
          }
          result[moment(y.cohort).format('MMM-YY')] =
            i + 1 <= y.cutoff_age ? parseFloat(val.toString()) : undefined
        })
        return result
      }),
  ]

  const export_columns = [
    {
      title: 'Cohort',
      field: 'x',
    },
    ...cohort
      .sort((a, b) => {
        return moment(a.cohort).diff(moment(b.cohort))
      })
      .map((c: { cohort: string; color: string }) => {
        return {
          title: `${moment(c.cohort).format('MMM YY')}`,
          field: `${moment(c.cohort).format('MMM-YY')}`,
        }
      }),
  ]

  /**
   * zoom
   */
  const xStart = 0
  const xSpan = Math.ceil(Number(avgTerm ?? 0) / 30) + 5
  const xEnd =
    chartData?.length === 0
      ? 0
      : xSpan >= chartData?.length
      ? chartData?.[chartData?.length - 1]?.x
      : chartData?.[xSpan - (xSpan > 0 ? 1 : 0)].x /
        chartData?.[chartData?.length - 1]?.x

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  return (
    <MultiToggleLayout
      toggles={toggles}
      toggleSelection={currTabs}
      exchangeRateIndicator={showHistoricalRateIndicator}
      staticTipInfo={
        <div>
          The percentage of the portfolio that is beyond a certain DPD cut-off,
          tracked against month-on-books.
        </div>
      }
      chart={
        <Chart
          loading={isFetching || isProcessing || avgTermIsFetching}
          id={`riskCohortVintage_by_${activeType}`}
          yLabel={!isVolume ? 'Number of Loans' : 'Volume'}
          yFormat="#.00a%"
          xLabel="Month on Books (MoB)"
          xAxisType="CategoryAxis"
          data={chartData}
          series={cohort.map((c: { cohort: string; color: string }) => {
            const label = `${isQuarter ? 'Q' : ''}${moment(c.cohort).format(
              isQuarter ? 'Q YY' : 'MMM YY'
            )}`
            return {
              label,
              tooltipValueFormat: '#.00a%',
              type: 'SmoothedXLineSeries',
              field: moment(c.cohort).format('MMM-YY'),
              color: c.color,
              hasBullet: false,
            }
          })}
          legendSetting={{
            show: true,
            position: 'right',
            config: { width: 124, paddingLeft: 24 },
          }}
          exportable={exportable}
          exportableColumn={export_columns}
          xlsxOptions={{ pivot: true, addColumnNames: true }}
          scroll={{ y: true, x: true, xStart, xEnd }}
          error={error as { message: string }}
        />
      }
      toggleData={
        <DataTable
          showHistoricalRateIndicator={showHistoricalRateIndicator}
          isVolume={isVolume}
          displayRates={display_rates}
          mob={mob}
          cohort={cohort}
          data={data}
          rates={rates}
          maxVal={maxVal}
          currency={currency}
          isQuarter={isQuarter}
        />
      }
    />
  )
}

export default VintageAnalysisL4
