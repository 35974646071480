import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart, { SeriesData } from '@components/chart'
import StatisticL5 from '@components/layouts/l5-stat'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import useToggleState from '@components/selectors/toggle-index-tracker'
import { REMOUNT_MS } from '@constants/config'
import { Role } from '@constants/role'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { CharacteristicService } from '@services/api-analytics/risk-characteristic'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'

const SEPARATOR = '-'

const Term = () => {
  const { currTabs, mapToggleChange } = useToggleState([0])
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)

  const [isDistribution, setIsDistribution] = useState<boolean>(true)

  const { userCompanyAccess, role, filterTogglesByAccess } =
    useUserAccessFeature()
  const activeAccess = userCompanyAccess?.access ?? {}
  const FEATURE = `analytics_risk_characteristics_term`

  const principalToggle: ToggleProps = {
    toggleTitle: 'Calculation Function',
    toggleSelections: filterTogglesByAccess(FEATURE, [
      {
        label: 'Distribution',
        action: () => {
          setIsDistribution(true)
        },
      },
      {
        label: 'Average',
        action: () => {
          setIsDistribution(false)
        },
      },
    ]),
  }

  const toggles: ToggleProps[] = mapToggleChange([principalToggle])

  const exportable =
    role !== Role.custom
      ? true
      : role === Role.custom &&
        activeAccess[
          `${FEATURE}_${isDistribution ? 'distribution' : 'average'}`
        ].includes('export')

  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    activeType,
    currency = 'USD',
  } = appliedFilters
  const {
    categoryTypeOptions = [],
    rates = {},
    display_rates = [],
  } = optionFilters
  const isAggregate = activeType === 'All'

  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  let maxVal = 0

  const filters: RisksFilters = {
    date_from: moment(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment(dateEndCohort).format('YYYY-MM-DD'),
    filters: isAggregate ? [] : categoryTypes,
    is_distribution: isDistribution,
    slug_name: company?.slug_name ?? '',
  }

  const { error, data, isFetching } = useQuery(
    ['termsDistribution', filters],
    () => CharacteristicService.getTerms(filters),
    getStaleMins()
  )
  const { bar_data, labels, ...growthRatios } = data ?? {}
  const cohort = ((bar_data || []) as any[]).filter(
    (v: { cohort: any }, i: any, a: { cohort: any }[]) =>
      a.findIndex((v2: { cohort: any }) => v2.cohort === v.cohort) === i
  )
  let chartData: SeriesData[]
  if (isDistribution) {
    chartData = (bar_data ?? []).map(x => ({
      ...x,
      x: moment(x.cohort).valueOf(),
    }))
  } else {
    chartData = cohort.map(c => {
      const result = historicalExchgList(rates, c, c.cohort)
      const cohortData = ((bar_data || []) as any[]).filter(
        d => d.cohort === c.cohort
      )
      if (isAggregate) {
        result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
        result.type_all_day = parseFloat(
          (Math.round(cohortData?.[0]?.val ?? 0) * 30).toString()
        )
        if (result.type_all > maxVal) {
          maxVal = result.type_all
        }
      } else {
        categoryTypes.forEach((ct: number) => {
          const cohortCategory = cohortData.find(
            (cd: { id: number }) => cd.id === ct
          )
          result[`type_${ct}`] = parseFloat(
            (cohortCategory?.val ?? 0).toString()
          )
          result[`type_${ct}_day`] = parseFloat(
            (Math.round(cohortCategory?.val ?? 0) * 30).toString()
          )
          if (result[`type_${ct}`] > maxVal) {
            maxVal = result[`type_${ct}`]
          }
        })
      }

      return result
    })
  }

  const is_day = maxVal <= 2

  const termAvgSeries = isAggregate
    ? [
        {
          label: 'All',
          tooltipLabel: `Terms`,
          tooltipValueFormat: `#.00a ${is_day ? 'days' : 'months'}`,
          type: 'SmoothedXLineSeries',
          field: `type_all${is_day ? '_day' : ''}`,
        },
      ]
    : (categoryTypes || []).map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: `${typeVal?.type}`,
          tooltipValueFormat: `#.00a  ${is_day ? 'days' : 'month'}`,
          field: `type_${ct}${is_day ? '_day' : ''}`,
          color,
          type: 'SmoothedXLineSeries',
          hasBullet: true,
        }
      })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  return (
    <MultiToggleLayout
      toggles={toggles}
      toggleSelection={currTabs}
      disableTooltip={true}
      exchangeRateIndicator={showHistoricalRateIndicator}
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`ristCharacteristicTerms_by_${activeType}_Isdistribution_${isDistribution}`}
          yLabel={`${
            isDistribution
              ? 'percentage'
              : `Term ${is_day ? '(day)' : '(month)'}`
          }`}
          yFormat={isDistribution ? '#.00a%' : undefined}
          data={chartData}
          series={
            isDistribution
              ? (labels ?? [])
                  .sort((a, b) => {
                    const aFromRange = a.val.toString().split(SEPARATOR)?.[0]
                    const bFromRange = b.val.toString().split(SEPARATOR)?.[0]
                    return Number(aFromRange) < Number(bFromRange) ? -1 : 1
                  })
                  .map(l => {
                    const start_end = l.val.toString().split(' ')[0]
                    const start_val = start_end.split(SEPARATOR)[0]
                    const start =
                      Number(start_val) < 2
                        ? `${(Number(start_val) * 30).toFixed(2)} days`
                        : `${Number(start_val).toFixed(2)} months`
                    const end_val = start_end.split(SEPARATOR)[1]
                    const end = end_val
                      ? Number(end_val) < 2
                        ? `${(Number(end_val) * 30).toFixed(2)} days`
                        : `${Number(end_val).toFixed(2)} months`
                      : undefined

                    return {
                      label: `${start} ${end ? ` - ${end}` : ''}`,
                      tooltipValueFormat: `#.00a%`,
                      type: 'ColumnSeries',
                      isStack: true,
                      field: `val_${l.id}`,
                    }
                  })
              : termAvgSeries
          }
          tooltipSubtitle={
            showHistoricalRateIndicator
              ? displayRates(display_rates)
              : undefined
          }
          exportable={exportable}
          error={error as { message: string }}
        />
      }
      stat={isDistribution ? <></> : <StatisticL5 {...growthRatios} />}
    />
  )
}

export default Term
