import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import StatisticL5 from '@components/layouts/l5-stat'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import useToggleState from '@components/selectors/toggle-index-tracker'
import Table from '@components/table'
import { ColumnProps } from '@components/table/type'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { createSeries } from '@helpers/risk-charts-data-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Filter } from '@interfaces/analytics-risk-filter-type-key'
import { TractionService } from '@services/api-analytics/risk-traction'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'

const Volume = () => {
  const { currTabs, mapToggleChange } = useToggleState([0])
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { get_access, filterTogglesByAccess } = useUserAccessFeature()

  const [isTotal, setIsTotal] = useState<boolean>(false)

  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes,
    currency = 'USD',
    activeType,
  } = appliedFilters

  const {
    categoryTypeOptions = [],
    rates = {},
    display_rates = [],
  } = optionFilters

  const FEATURE = `analytics_risk_traction_volume`

  const principalToggle: ToggleProps = {
    toggleTitle: 'Series Interval',
    toggleSelections: filterTogglesByAccess(FEATURE, [
      {
        label: 'Monthly',
        textInfo: {
          primary:
            'The total principal of loans disbursed in a monthly cohort.',
          secondary:
            'Formula: Aggregate sum of all Disbursed Principal for loans in a cohort.',
        },
        action: () => {
          setIsTotal(false)
        },
      },
      {
        label: 'Total',
        textInfo: {
          primary:
            'The total principal of loans disbursed since the beginning of operations.',
          secondary:
            'Formula: Aggregate sum of all Disbursed Principal for loans since the beginning.',
        },
        action: () => {
          setIsTotal(true)
        },
      },
    ]),
  }

  const toggles: ToggleProps[] = mapToggleChange([principalToggle])

  // exportable is determined by principal's selections
  const exportable = get_access(
    `${FEATURE}_${principalToggle.toggleSelections[
      currTabs[0]
    ].label.toLowerCase()}`,
    'export'
  )

  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const filters: RisksFilters = {
    date_from: moment(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    is_cumulative: isTotal,
    is_aggregate: isAggregate,
    currency,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['volume', isTotal, filters],
    () => TractionService.getVolume(filters),
    {
      ...getStaleMins(),
      enabled:
        !!currency &&
        !!company?.slug_name &&
        !!dateStartCohort &&
        !!dateEndCohort,
    }
  )

  const { data, ...growthRatios } = fetchedData ?? {}

  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  const chartData = cohort.map(c => {
    const result = historicalExchgList(rates, c, c.cohort)
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString()) //  * fx
    } else {
      categoryTypes.forEach((ct: number) => {
        const cohortCategory = cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString()) //  * fx
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [currency, data])

  const series = createSeries(
    isAggregate,
    categoryTypes,
    activeVal,
    currency,
    false,
    'All',
    'Volume'
  )

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      width: 200,
      render: (r: any) => {
        return moment.utc(r.x).format('YYYY-MM-DD')
      },
    },
    ...series.map((s: any) => {
      return {
        width: 200,
        align: 'right',
        field: s.field,
        title: s.label,
        render: (r: any) => numberFormatter(r[s.field]),
      }
    }),
  ]

  return (
    <MultiToggleLayout
      toggles={toggles}
      toggleSelection={currTabs}
      exchangeRateIndicator={showHistoricalRateIndicator}
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`riskTractionVolume${isTotal ? '_total' : '_'}by_${activeType}`}
          yLabel={currency}
          data={chartData}
          series={series}
          tooltipSubtitle={
            showHistoricalRateIndicator
              ? displayRates(display_rates)
              : undefined
          }
          error={error as { message: string }}
          exportable={exportable}
          exportableColumn={table_columns}
        />
      }
      stat={<StatisticL5 {...growthRatios} />}
      toggleData={
        <Table
          containerClass=""
          loading={isFetching || isProcessing}
          columns={table_columns as ColumnProps[]}
          data={chartData}
        />
      }
    />
  )
}

export default Volume
