import React from 'react'

interface TableProps {
  isVolume: boolean
  dpds: string[]
  currentMoBs: number[]
  currentData: any[]
  currency: string
}

const DataTable: React.FC<TableProps> = ({
  isVolume,
  dpds,
  currentMoBs,
  currentData,
  currency,
}) => {
  return (
    <div
      className={`overflow-auto rounded-lg border border-neutral-border-1 max-h-[calc(100vh-100px)] cascade-table`}
    >
      <table className="border-separate border-spacing-0">
        <thead className="sticky top-0 z-10">
          <tr>
            <th className="p-3 border-r border-b border-neutral-border-1 text-sm text-neutral-body-1 lg:min-w-[50px] sticky left-0 bg-neutral-white">
              MoB
              <span className="absolute right-0 top-0 h-full border-r border-neutral-border-1"></span>
            </th>
            <th className="p-3 border-r border-b border-neutral-border-1 text-sm text-neutral-body-1 lg:min-w-[120px] sticky left-[50px] bg-neutral-white">
              {`${!isVolume ? 'Number of Loans' : 'Volume'}`}
              <span className="absolute right-0 top-0 h-full border-r border-neutral-border-1"></span>
            </th>
            <th className="p-3 border-r border-b border-neutral-border-1 text-sm text-neutral-body-1 lg:min-w-[120px] sticky left-[170px] bg-neutral-white">
              {`Outstanding ${!isVolume ? 'Number of Loans' : 'Volume'}`}
              <span className="absolute right-0 top-0 h-full border-r border-neutral-border-1"></span>
            </th>
            {dpds.map((x, i) => (
              <th
                key={i}
                className="p-3 min-w-[120px] border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1"
              >
                {x}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentMoBs.map(m => {
            return (
              <tr key={m}>
                <td className="p-3 border-r border-b border-neutral-border-1 sticky left-0 bg-neutral-white text-sm text-center">
                  {m}
                  <span className="absolute right-0 top-0 h-full border-r border-neutral-border-1"></span>
                </td>
                {dpds.map((_d, i) => {
                  const columnData = currentData.find(
                    x => x.mob === m && x.bucket_label === _d
                  )
                  const val = !columnData ? 0 : Number(columnData?.val)
                  const firstBucketData = currentData.find(x => x.mob === m)

                  return (
                    <>
                      <>
                        {i === 0 && (
                          <>
                            <td className="p-3 border-r border-b border-neutral-border-1 text-sm text-right sticky left-[50px] bg-neutral-white">
                              {`${Intl.NumberFormat(undefined, {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                                currency,
                                notation: 'compact',
                              }).format(firstBucketData?.total ?? 0)} ${
                                !isVolume ? '' : currency
                              }`}
                              <span className="absolute right-0 top-0 h-full border-r border-neutral-border-1"></span>
                            </td>
                            <td className="p-3 border-r border-b border-neutral-border-1 text-sm text-right sticky left-[170px] bg-neutral-white">
                              {`${Intl.NumberFormat(undefined, {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                currency,
                                notation: 'compact',
                              }).format(firstBucketData?.outstanding)} ${
                                !isVolume ? '' : currency
                              }`}
                              <span className="absolute right-0 top-0 h-full border-r border-neutral-border-1"></span>
                            </td>
                          </>
                        )}
                      </>
                      <td
                        key={i}
                        className="p-3 border-r border-b border-neutral-border-1 text-sm text-right"
                      >
                        {Intl.NumberFormat(undefined, {
                          style: 'percent',
                          notation: 'compact',
                          maximumFractionDigits: 2,
                        }).format(val / 100)}
                        <span className="absolute right-0 top-0 h-full border-r border-neutral-border-1"></span>
                      </td>
                    </>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default DataTable
