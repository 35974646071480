import React from 'react'
import moment from 'moment'

import { redGreenHeatmap } from '@helpers/heatmap-palettes'

interface TableProps {
  showHistoricalRateIndicator: boolean
  displayRates: any[]
  mob: number
  cohort: { cohort: string; color: string }[]
  data: any
  rates: Record<string, any>
  maxVal: number
}

const DataTable: React.FC<TableProps> = ({
  showHistoricalRateIndicator,
  displayRates,
  mob,
  cohort,
  data,
  rates,
  maxVal,
}) => {
  return (
    <div
      className={`overflow-auto rounded-lg border border-neutral-border-1 max-h-[calc(100vh-100px)]`}
    >
      <table className="border-separate border-spacing-0">
        <thead className="sticky top-0 z-20">
          <tr>
            <th className="p-3 border-r border-b border-neutral-border-1 text-sm text-neutral-body-1 lg:min-w-[100px] sticky left-0 bg-neutral-white">
              Cohort
            </th>
            {showHistoricalRateIndicator &&
              displayRates.map((r: any) => (
                <th
                  key={r}
                  className="p-3 border-r border-b border-neutral-border-1 text-sm text-neutral-body-1 lg:min-w-[100px] bg-neutral-white"
                >
                  USD:{r}
                </th>
              ))}
            {Array(mob)
              .fill('')
              .map((_, i) => (
                <th
                  key={i}
                  className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1"
                >
                  {i + 1}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {cohort
            .sort((a, b) => moment(a.cohort).diff(moment(b.cohort)))
            .map((c: { cohort: string; color: string }) => {
              const rowData = (data?.data ?? []).filter(
                (x: any) => x.cohort === c.cohort
              )

              return (
                <tr key={c.cohort}>
                  <td className="p-3 border-r border-b border-neutral-border-1 sticky left-0 text-sm text-center bg-neutral-white z-10">
                    {`${moment(c.cohort).format('MMM YY')}`}
                  </td>
                  {showHistoricalRateIndicator &&
                    displayRates.map((r: any) => (
                      <td
                        key={r}
                        className="p-3 border-r border-b border-neutral-border-1  text-sm text-right bg-neutral-white z-10"
                      >
                        {Intl.NumberFormat(undefined, {
                          style: 'decimal',
                          maximumFractionDigits: 2,
                        }).format(rates[c.cohort]?.[r] ?? 1)}
                      </td>
                    ))}

                  {Array(mob)
                    .fill('')
                    .map((_, i) => {
                      const dt = rowData.find((x: any) => x.mob === i + 1)
                      if (i + 1 > (rowData[0]?.cutoff_age ?? 0)) {
                        return <td key={i} className="bg-neutral-border-1" />
                      }
                      const cellVal =
                        dt?.numer && dt.denom
                          ? parseFloat(dt.numer.toString()) /
                            parseFloat(dt.denom.toString())
                          : 0
                      const valRange =
                        maxVal > 0 ? Math.floor((cellVal / maxVal) * 100) : 0

                      const backgroundColor =
                        redGreenHeatmap[Math.max(0, 100 - valRange)]
                      return (
                        <td
                          key={i}
                          className={`p-3 border-r border-b border-neutral-border-1 text-sm text-right relative text-white`}
                          style={{ backgroundColor }}
                        >
                          {Intl.NumberFormat(undefined, {
                            style: 'percent',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(cellVal)}
                        </td>
                      )
                    })}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default DataTable
